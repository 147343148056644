<template>
  <div>
    <HomePageTop/>
    <div style="margin-top: -32px">
      <header>
        <div>お問い合わせ</div>
      </header>
      <section>
        <div class="sectionContainer">
          <div style="line-height:48px;margin-bottom: 35px;color: #000000;">
            お問合せへの回答をお送りするため、ご連絡先の明記をお願いいたします。<br>

            取扱い商品の検索・詳細仕様の確認はご登録ユーザー様のみへのサービスです。<br>

            お問合せは、事業者様を対象としております。(事業者であれば職人の方なども可能です)
          </div>
          <div class="rakumartMessageListContainer">
            <div class="rakumartMessageContainer">
              <div class="leftContainer"><img src="../../assets/foot-img/contactUsTel.png" alt=""></div>
              <div class="rakumartMessageTextContainer">
                <div>電話番号</div>
                <div>06-7636-8120</div>
              </div>
            </div>
            <div class="rakumartMessageContainer" @click="lianxi">
              <div class="rightContainer"><img src="../../assets/foot-img/contactUsChat.png" alt=""></div>
              <div class="rakumartMessageTextContainer">
                <div style="margin-left: 80px;">チャットツール</div>
                <!--                <div>点击开始聊天</div>-->
                <div style="margin-left: 20px;"> クリックして、臨時チャット開始</div>
              </div>
            </div>
          </div>
          <div style="margin-bottom: 10px;padding-left: 17px;width: 1200px">
            日本国内電話受付時間：10:00~18:30（土日、祝日（日本）を除く）
          </div>
          <div style="margin-bottom: 30px;width: 1200px">チャットツール受付時間：9：30～18：30（日、祝日（中国）を除く）</div>
          <div style="line-height: 24px;width: 1200px">
            ・上記時間外の受付に関しては翌営業日以降のご返信とさせていただきます。<br>
            ・お問い合わせ内容によっては翌営業日以降のご返信となる場合がございます。予めご了承ください。
          </div>
          <div style="line-height: 24px;width: 1200px">
            <div>・回線混雑等によりお電話が繋がりにくい場合がございます。</div>
            <div>各種サービスに関してご不明な点がございましたら、<span class="pageJump"
                                                                      @click="pageJump">よくある質問</span>をご確認ください。
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div style="line-height: 24px;text-align: center;margin-bottom: 20px">
          Rakumartをご利用頂きましてありがとうございます。<br>
          中国仕入れについて、または弊サイトの使い方についてご不明な点がありましたら、<br>
          電話、チャットツール、又は下記お問合せフォームにてご連絡ください。
        </div>
        <div style="text-align: center;margin-bottom: 60px">
          お急ぎの方は電話やチャットツールをご利用ください。簡単にお問合せができます。
        </div>
        <div class="fromContainer">
          <div class="title">*は入力必須項目です※は必ず入力してくだい。</div>
          <el-form
              class="firStepForm"
              :model="form"
              :rules="formRules"
              ref="formRef"
              label-position="top">
            <el-form-item prop="name">
              <div class="flexAndCenter">
                <div class="flex">
                  <div class="fontSize18 colorB4272B">*</div>
                  <div class="fontSize18">氏名</div>
                </div>
                <div class="fontSize14 colorB4272B">（フルネーム）</div>
              </div>
              <div class="formInput">
                <el-input v-bind:class="{istrueInput: formInputTrue.name,}"
                          class="ipt"
                          @focus="validateField('name')"
                          @blur="validateField('name')"
                          v-model="form.name"/>
                <img src="../../assets/verifyTrue.png" v-show="formInputTrue.name===true">
                <img src="../../assets/verifyFalse.png" v-show="formInputTrue.name===false">
              </div>
            </el-form-item>
            <el-form-item>
              <div class="flexAndCenter">
                <div>お客様ID</div>
                <div class="fontSize14 colorB4272B">（会員ID又は注文/配送ナンバー）</div>
              </div>
              <div class="formInput newFormInput">
                <el-input class="ipt passWordInputBox" v-model="form.userID"/>
              </div>
            </el-form-item>
            <el-form-item prop="tel" label="電話">
              <div class="formInput">
                <el-input v-bind:class="{istrueInput: formInputTrue.tel}" class="ipt"
                          @focus="validateField('tel')"
                          @blur="validateField('tel')"
                          v-model="form.tel"/>
                <img src="../../assets/verifyTrue.png" v-show="formInputTrue.tel===true">
                <img src="../../assets/verifyFalse.png" v-show="formInputTrue.tel===false">
              </div>
            </el-form-item>
            <el-form-item prop="email" label="メールアドレス">
              <div class="formInput newFormInput">
                <el-input class="ipt passWordInputBox"
                          v-bind:class="{elistrueInput: formInputTrue.email,}"
                          @focus="validateField('email')"
                          @blur="validateField('email')"
                          v-model="form.email"/>
                <img src="../../assets/verifyTrue.png" v-show="formInputTrue.email===true">
                <img src="../../assets/verifyFalse.png" v-show="formInputTrue.email===false">
              </div>
            </el-form-item>
            <el-form-item prop="remark" label="お問い合わせ内容">
              <div class="formInput newFormInput">
                <el-input type="textarea" class="ipt passWordInputBox"
                          v-bind:class="{elistrueInput: formInputTrue.remark,}"
                          @focus="validateField('remark')"
                          @blur="validateField('remark')"
                          :rows="10"
                          v-model="form.remark" resize="none"/>
              </div>
            </el-form-item>
          </el-form>
          <div class="btn" @click="submit">問い合わせ内容を確認する</div>
        </div>
      </footer>
    </div>
    <FootVue/>
  </div>
</template>

<script>
import FootVue from "../../components/foot/Foot.vue";
import HomePageTop from "../../components/head/HomePageTop.vue";

export default {
  components: {
    FootVue,
    HomePageTop
  },
  data() {
    return {
      form: {},
      formInputTrue: {},
      formRules: {
        name: [
          {
            required: true,
            message: this.$fanyi("请输入氏名"),
            trigger: "blur",
          },
          {min: 1, max: 24, message: this.$fanyi("长度在 1 到 24 个字符"), trigger: 'blur'}
        ],
        tel: [
          {
            required: true,
            message: this.$fanyi("请输入手机号"),
            trigger: "blur",
          }
        ],
        email: [
          {
            required: true,
            message: this.$fanyi("请输入正确的邮箱地址"),
            trigger: "blur",
          },
          {
            pattern: /^.+@.+$/i,
            message: this.$fanyi("请输入正确的邮箱地址"),
            trigger: ["blur", "change"],
          },
        ],
        remark: [
          {
            required: true,
            message: this.$fanyi("请输入咨询内容"),
            trigger: "blur",
          },
        ]
      }
    }
  },
  methods: {
    // 手动触发表单验证
    validateField(type) {
      if (this.form[type])
        this.form[type] = this.form[type].trim();
      this.$refs.formRef.validateField(type, (v) => {
        if (v == "") {
          this.formInputTrue[type] = true;
        } else {
          this.formInputTrue[type] = false;
        }
        this.$forceUpdate();
      });
    },
    pageJump() {
      window.open('/questionAndAnswer')
    },
    async lianxi() {
      if (this.$fun.isOfficialWeb()) {
        let user_token = localStorage.getItem('japan_user_token')
        if (user_token != null) {
          // if (this.$store.state.userInfo.chatwork == '') {
          this.loading = this.$loading({
            lock: true,
            spinner: "el-icon-loading fontSize50",
            background: "rgba(255, 255, 255, 0.7)",
          });
          await this.$api.userTimGroupCheck({user_id: this.$store.state.userInfo.operation_id}).then(() => {
            this.loading.close();
            var a = document.createElement("a");
            a.setAttribute("href", `https://rakuchat.rakumart.com/client_web/#/?id=${this.$store.state.userInfo.operation_id}&token=${user_token}`);
            a.setAttribute("target", '_blank')
            let clickEvent = document.createEvent("MouseEvents");
            clickEvent.initEvent("click", true, true);
            a.dispatchEvent(clickEvent);
          });
          // } else {
          //   var a = document.createElement("a");
          //   a.setAttribute("href", `https://www.chatwork.com/#!rid' + ${this.$store.state.userInfo.chatwork}`);
          //   a.setAttribute("target", '_blank')
          //   let clickEvent = document.createEvent("MouseEvents");
          //   clickEvent.initEvent("click", true, true);
          //   a.dispatchEvent(clickEvent);
          // }
        } else {
          window.open('https://rakuchat.rakumart.com/visitor_web/#/')
        }
      }
    },
    submit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.$api.addFeedbackUnLogin({
            realname: this.form.name,
            user_id: this.form.userID,
            mobile: this.form.tel,
            email: this.form.email,
            remark: this.form.remark
          }).then((res) => {
            if (res.code != 0) return this.$message.error(this.$fanyi(res.msg))
            this.$message.success(this.$fanyi(res.msg))
            setTimeout(() => {
              window.close();
            }, 2000)
          });
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  background: url("../../assets/foot-img/contactUsBanner.png") no-repeat;
  width: 100%;
  height: 350px;
  background-size: 100% 100%;
  padding-top: 153px;
  text-align: center;

  div {
    text-shadow: 0 0 27px rgba(0, 0, 0, 0.4);
    color: #FFFFFF;
    font-weight: bold;
    font-size: 48px;
    letter-spacing: 15px;
  }
}

section {
  width: 100%;
  background: #fff;
  padding: 80px 0;

  .sectionContainer {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .rakumartMessageListContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 35px;

    .rakumartMessageContainer {
      width: 560px;
      height: 200px;
      background: #FFFFFF;
      box-shadow: 0 0 16px 4px rgba(6, 0, 1, 0.05);
      border-radius: 6px;
      padding: 40px 0 40px 60px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      cursor: pointer;

      .leftContainer, .rightContainer {
        width: 120px;
        height: 120px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .rakumartMessageTextContainer {
        div:first-child {
          color: #000000;
          font-weight: bold;
          font-size: 24px;
          margin-bottom: 24px;
        }

        div:last-child {
          color: #B4272B;
          font-size: 20px;
        }
      }
    }
  }
}

.pageJump {
  color: #B4272B;
  cursor: pointer;
}

footer {
  padding: 80px 0;
  background: #F6F6F6;
  display: flex;
  flex-direction: column;
  align-items: center;

  .fromContainer {
    width: 700px;
    background: #FFFFFF;
    box-shadow: 0 0 16px 4px rgba(6, 0, 1, 0.05);
    border-radius: 12px;
    padding: 60px 100px;
    box-sizing: border-box;

    .title {
      color: #B4272B;
      font-size: 14px;
      margin-bottom: 40px;
    }
  }

  .formInput {
    position: relative;

    img {
      position: absolute;
      right: 30px;
      top: 10px;
    }
  }

  .btn {
    width: 500px;
    height: 50px;
    background: #B4272B;
    border-radius: 3px;
    line-height: 50px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
  }
}
</style>
